// fun facts area

.fun-facts-area-1{
    background-image: url(../images/fun-facts/01.jpg);
    position: relative;
    z-index: 1;
    margin: auto;
    .shape-image{
        .shape{
            position: absolute;
            z-index: -1;
            &.one{
                left: 6%;
                bottom: 30%;
            }
            &.three{
                right: 10%;
                top: 35%;
            }
        }
    }
}

.fun-facts-main-wrapper-1{
    padding: 0 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media #{$md-layout} {
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 30px;
        .single-fun-facts{
            @media #{$md-layout} {
                flex-basis: 45%;
            }
            &::after{
                display: none;
            }
        }
    }
    @media #{$sm-layout} {
        align-items: flex-start;
        gap: 30px;
        padding: 0 22px;
        flex-wrap: wrap;
        .single-fun-facts{
            &::after{
                display: none;
            }
        }
    }
    .single-fun-facts{
        @media #{$sm-layout} {
            flex-basis: 45%;
        }   
        @media #{$large-mobile} {
            flex-basis: 42%;
        }
    }
}

.single-fun-facts{
    position: relative;
    &:last-child{
        &::after{
            display: none;
        }
    }
    &::after{
        position: absolute;
        content: '';
        right: -70%;
        top: 0;
        width: 1px;
        height: 100%;
        background: #725AF9;
        @media #{$smlg-device} {
            display: none;
        }
    }
    .title{
        margin-top: 20px;
        margin-bottom: 2px;
        span{
            color: #fff;
            font-size: 22px;
        }
    }
    span.enr{
        color:  #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px /* 160% */
    }
}


.bg-dark-image{
    .title-area-left-style{
        span{
            color: #fff;
        }
        .title{
            color: #fff;
            @media #{$smlg-device} {
                br{
                    display: none;
                }
            }
            @media #{$md-layout} {
                font-size: 42px;
            }
            @media #{$sm-layout} {
                font-size: 32px;
            }
            br{
                @media #{$large-mobile} {
                    display: none;
                }
            }
            span span{
                margin-top: 10px;
            }
        }
    }

}

.rts-fun-facts-area-3{
    background-image: url(../images/fun-facts/02.jpg);
    position: relative;
    z-index: 2;
}



.counter-remaining-area{
    display: flex;
    align-items: center;
    gap: 20px;
    @media #{$large-mobile} {
        flex-wrap: wrap;
    }
    .single-counter{
        height: 93px;
        min-width: 93px;
        border-radius: 50%;
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        .title{
            margin-bottom: 0;
            span{
                color:#FFF;
                text-align: center;
                font-size: 36px;
                font-style: normal;
                font-weight: 600;
                line-height: 10px;
                span{
                    display: block;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
}


.rts-fun-facts-area-3{
    .title{
        position: relative;
        z-index: 1;
        img{
            position: absolute;
            left: 40%;
            bottom:9px;
            z-index: -1;
        }
    }
    .title-area-left-style{
        .title{
            @media(max-width:1200px) and (min-width:991px){
                font-size: 42px;
               br{
                display: none;
               } 
            }
        }
    }
}


.registration-area-right{
    margin-bottom: -270px;
    max-width: 460px;
    padding: 40px;
    border-radius: 6px;
    background:  #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
    @media #{$md-layout} {
        margin-bottom: 0;
        width: 100%;
        max-width: 100%;
    }
    @media #{$sm-layout} {
        margin-bottom: 0;
        padding: 20px;
        width: 100%;
        max-width: 100%;
    }
    @media(max-width:576px){
        max-width: 100%;
    }
    input{
        height: 46px;
        border: 1px solid  #DDD8F9;
        border-radius: 4px;
        font-size: 14px;
    }
    .registration{
        .half-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            margin-bottom: 20px;
            @media screen and (max-width:575px) {
                flex-wrap: wrap;
            }
        }
    }
    textarea{
        height: 142px;
        border: 1px solid  #DDD8F9;
        border-radius: 4px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 10px 13px;
    }
}

.counter-remaining-area{
    .details{
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 26px;
    }
}

.fun-facts-main-wrapper-1.style-two{
    border: 1px solid #E9E5FF;
    padding: 32px 120px;
    box-shadow: 0px 4px 30px 0px rgba(177, 167, 241, 0.10);
    background: #fff;
    @media #{$sm-layout} {
        padding: 22px;
    }
    @media #{$large-mobile} {
        padding: 10px;
    }
    .single-fun-facts::after {
        position: absolute;
        content: "";
        right: -70%;
        top: 0;
        width: 1px;
        height: 100%;
        background: #DDD8F9;
    }
    .title{
        color: #110C2D;
        span{
            color: #110C2D;
        }
    }
    span.enr{
        color: #737477;
    }
}
.fun-facts-main-wrapper-1.style-seven{
    background: var(--color-primary-gym);
    padding: 32px 120px;
    box-shadow: 0px 4px 30px 0px rgba(177, 167, 241, 0.10);
    @media(max-width:1200px){
        padding: 32px 90px;
    }
    @media(max-width:991px){
        padding: 32px 50px;
    }
    @media(max-width:576px){
        padding: 32px 10px;
    }
    .single-fun-facts{
        @media(max-width:400px){
            flex-basis: 100%;
            text-align: center;
        }
        &::after {
            position: absolute;
            content: "";
            right: -70%;
            top: 0;
            width: 1px;
            height: 100%;
            background: #DDD8F9;
            @media(max-width:1200px){
                right: -30%;
            }
        }
    }
    .title{
        color: #FFFFFF;
        font-family: 'Teko', sans-serif;
        span{
            color: #FFFFFF;
        }
    }
    span.enr{
        color: #FFFFFF;
    }
}

.radious-0{
    border-radius: 0;
}

.category-single-list.radious-0 .thumbnail img {
    transition: 0.3s;
    transform: scale(1.1);
}

.category-single-list.radious-0{
    .thumbnail{
        border-radius: 0 !important;
    }
    &:hover{
        .thumbnail{
            img{
                transform: scale(1.2);
            }
        }
    }
}

.call-to-sction.style-three{
    text-align: center;
    background-image: url(../images/cta/03.jpg);
    flex-direction: column;
    .title{
        margin: 0;
    }
}

.cta-form{
    position: relative;
    display: block;
    width: 60%;
    margin: auto;
    margin-top: 30px;
    @media #{$large-mobile} {
        width: 100%;
    }
    input{
        height: 56px;
        background: #FFF;
        display: block;
        width: 100%;
    }
    .rts-btn{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px 20px;
    }
}


.mt-dec-fun-f{
    margin-top: -100px;
    position: relative;
    z-index: 10;
}
.fun-facts-area-7{
    margin-top: -60px;
    @media(max-width:576px){
        margin-top: 0;
        background: var(--color-primary-gym);
    }
}

.app-download-area{
    background: #ffffff;
    .download-full-content-bg{
        background: url(../images/fun-facts/app-download-2.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 80px 150px;
        overflow: visible;
        position: relative;
        transition: all .4s;
        border-radius: 10px;
        @media(max-width:1200px){
            padding: 80px 50px;
        }
        @media(max-width:991px){
            padding: 80px 150px 80px 50px;
        }
        @media(max-width:768px){
            padding: 80px 50px;
        }
        @media(max-width:576px){
            padding: 80px 30px;
        }
        @media(max-width:450px){
            padding: 50px 15px;
        }
        &:hover{
            .download-img-content{
                transform: scale(103%);
            }
        }
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: #181823;
            opacity: 0;
        }
        .download-img-content{
            position: absolute;
            bottom: 0;
            right: 12%;
            transition: all 0.4s;
            transform: scale(100%);
            transition: all 0.4s;
            @media(max-width:1200px){
                right: 0;
            }
            @media(max-width:991px) and (min-width:768px){
                max-width: 200px;
                right: 2%;
                top: 50%;
                transform: translateY(-50%) scale(100%);
                bottom: unset;
            }
            @media(max-width:768px){
                max-width: max-content;
                position: unset;
                transform: scale(100%);
            }
        }
        .download-full-content{
            display: flex;
            position: relative;
            z-index: 1;
            .download-text-content{
                .download-title{
                    h2{
                        font-size: 48px;
                        line-height: 52px;
                        color: #fff;
                        font-weight: 600;
                        text-transform: uppercase ;
                        @media(max-width:768px){
                            font-size: 42px;
                            line-height: 48px;
                        }
                        @media(max-width:576px){
                            font-size: 34px;
                            line-height: 46px;
                        }
                        @media(max-width:450px){
                            font-size: 24px;
                            line-height: 34px;
                        }
                    }
                }
                .download-sub-title{
                    p{
                        color: #fff;
                        font-size: 16px;
                        line-height: 26px;
                        font-weight: 500;
                        @media(max-width:576px){
                            br{
                                display: none;
                            }
                        }
                    }
                }
                .download-place{
                    display: flex;
                    gap: 25px;
                    margin-top: 32px;
                    @media(max-width:450px){
                        flex-wrap: wrap;
                    }
                    a{
                        border: 1px solid #fff;
                        padding: 15px 30px;
                        color: #fff;
                        position: relative;
                        font-size: 20px;
                        font-weight: 600;
                        border-radius: 4px;
                        transition: all .3s;
                        @media(max-width:576px){
                            padding: 12px 20px;
                            font-size: 16px;
                        }
                        &:hover{
                            background: #FFFFFF;
                            color: var(--color-primary-kitchen);
                            svg{
                                path{
                                    fill: var(--color-primary-kitchen);
                                }
                            }
                        }
                        svg{
                            margin-right: 8px;
                            transition: all .3s;
                            path{
                                transition: all .3s;
                            }
                        }
                    }
                }
            }
        }
        &.v-7{
            background: url(../images/fun-facts/app-download.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover; 
            .download-full-content{
                display: flex;
                position: relative;
                z-index: 1;
                .download-text-content{
                    .download-place{
                        a{
                            border-radius: 0;
                            &:hover{
                                color: var(--color-primary-gym);
                                svg{
                                    path{
                                        fill: var(--color-primary-gym);
                                    }
                                }
                            }
                        }
                    }
                }
                .download-text-content{
                    .download-title{
                        h2{
                            font-size: 64px;
                            line-height: 64px;
                            color: #fff;
                            font-weight: 600;
                            font-family: 'Teko', sans-serif;
                            text-transform: uppercase ;
                            @media(max-width:1200px){
                                font-size: 60px;
                                line-height: 60px;
                            }
                            @media(max-width:576px){
                                font-size: 34px;
                                line-height: 46px;
                            }
                            @media(max-width:450px){
                                font-size: 24px;
                                line-height: 34px;
                            }
                        }
                    }
                    .download-sub-title{
                        p{
                            color: #fff;
                            font-size: 16px;
                            line-height: 26px;
                            font-weight: 500;
                            @media(max-width:576px){
                                br{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            } 
        }
    }
}
