// create course scss


.create-course-area-main-wrapper-inner{
    .accordion-item{
        margin-bottom: 75px;
        border-color: #DDD8F9;
        border-radius: 6px;
        .accordion-header{
            &:focus{
                box-shadow: none;
                border: none;
            }
            button{
                padding: 24px 30px;
                border: 1px solid #DDD8F9;
                background: transparent;
                color:  #110C2D;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                &::after{
                    content: "\f055";
                    font-family: var(--font-3);
                    background-image: none;
                    transform: none;
                    top: 29%;
                    position: absolute;
                    right: 30px;
                    font-weight: 400;
                }
                &:focus{
                    box-shadow: none;

                }
                &[aria-expanded="true"]{
                    &::after{
                        content: "\f056";
                    }
                }
            }
        }
        .accordion-body{
            padding: 25px;
            .course-information-area{
                .top-form-create-course{
                    .single-input{
                        margin-top: 30px;
                        &:first-child{
                            margin-top: 0;
                        }
                        label{
                            margin-bottom: 20px;
                            color: #110C2D;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 24px;
                        }
                        input{
                            border-radius: 6px;
                            border: 1px solid #DDD8F9;
                            height: 50px;
                            &:focus{
                                border-color: var(--color-primary);
                            }
                        }
                        textarea{
                            height: 227px;
                            border-radius: 6px;
                            border: 1px solid  #DDD8F9;
                            padding: 10px 15px;
                            &:focus{
                                border-color: var(--color-primary);
                            }
                        }
                    }
                }
                .course-setting-title{
                    margin-top: 20px;
                }
            }
        }
    }
}

.create-course-tab-area{
    display: flex;
    align-items: flex-start;
    gap: 30px;
    .nav-tabs{
        flex-basis: 22%;
        flex-direction: column;
        margin-top: 0;
        .nav-item{
            border: none;
            box-shadow: none;
            margin: 0;
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
            button{
                border-radius: 4px;
                border: 1px solid  #553CDF;
                padding: 12px 14px;
                color: var(--color-primary);
                i{
                    color: var(--color-primary);
                }
                &.active{
                    background: var(--color-primary);
                    color: #fff;
                    i{
                        color: #fff;
                    }
                }
            }
        }
    }
    .tab-content{
        flex-basis: 75%;
    }
}



.create-course-tab-area{
    padding: 30px;
    border-radius: 6px;
    border: 1px solid  #DDD8F9;
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
    }
    @media #{$large-mobile} {
        padding: 20px;
    }
    .generale-tab-content{
        padding: 34px;
        border: 1px solid #DDD8F9;
        border-radius: 6px;
        display: block;
        width: 100%;
        background: #F9F8FF;
        .single-checkbox-filter{
            .check-box{
                display: block;
            }
        }
    }
    .choosea-category-input{
        margin-top: 25px;
        label{
            color: #110C2D;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 15px;
        }
        input{
            border-radius: 6px;
            border: 1px solid  #DDD8F9;
            height: 56px;
        }
        .check-box-wrapper{
            display: flex;
            align-items: center;
            gap: 40px;
            margin-left: 5px;
        }
        .regular-price-discount-price-area{
            display: flex;
            align-items: center;
            gap: 30px;
            @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
            }
            .input-group{
                label{
                    display: block;
                }
                span{
                    min-width: 34px;
                    font-size: 18px;
                    padding: 10px 18px;
                }
            }
        }
    }
}


.course-thumbnail-upload-area{
    display: flex;
    align-items: center;
    gap: 30px;
    @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
    }
    .information{
        span{
            display: block;
        }
        .input-file-type-btn{
            position: relative;
            margin-top: 10px;
        }
        button{
            padding: 13px 25px;
        }
        input{
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            left: 0;
            top: 0;
            cursor: pointer;
        }
    }
}

.course-info-video-link{
    input{
        height: 50px;
        border: 1px solid #DDD8F9;
        border-radius: 6px;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .nice-select-wrap{
        margin-bottom: 20px;
        .drop{
            height: 50px;
            border: 1px solid #DDD8F9;
            border-radius: 6px;
            font-size: 16px;
            line-height: 41px;
            &::after{
                right: 25px;
                font-size: 14px;
            }
        }
        ul#price{
            width: 270px !important;
            border: 1px solid #DDD8F9;
        }
    }
}


.course-upload-tips-wrapper{
    border-radius: 6px;
    border: 1px solid #DDD8F9;
    background: #F9F8FF;
    padding: 30px !important;
    .single-check-wrapper{
        display: flex;
        align-items: flex-start;
        gap: 15px;
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
        i{
            color: var(--color-primary);
            margin-top: 7px;
        }
    }
}




// additional data dorm

.additional-data-form{
    .single-input-area{
        label{
            color:  #110C2D;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 15px;
        }
        textarea{
            height: 100px;
            border-radius: 6px;
            border: 1px solid  #DDD8F9;
            padding: 15px;
        }
    }
    .course-duration-input-area{
        display: flex;
        align-items: flex-end;
        gap: 30px;
        width: 100%;
        margin-top: 25px;
        .half-single-input{
            flex-basis: 50%;
            p{
                margin-bottom: 20px;
                color: #110C2D;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
            input{
                height: 50px;
                border-radius: 6px;
                border: 1px solid  #DDD8F9;
            }
            label{
                margin-top: 15px;
                margin-left: 5px;
            }
        }
    }
}






.certificate-template-tabs{
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    gap: 10px;
    li{
        button{
            padding: 12px 25px;
            border-radius: 4px;
            border: 1px solid  #553CDF !important;
            color: #553CDF;
            &[aria-selected="true"]{
                background-color: #553CDF !important;
                color: #F9F8FF !important;
            }
        }
    }
}




.certificates-checkbox-tabs{
    .rts-image-check-box{
        label{
            img{
                width: 100%;
            }
            display: inline-block;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
            padding-left: 0 !important;
            border-radius: 6px;
            &::after{
                background-image: url(../images/dashboard/certificates/10.png) !important;
                opacity: 0;
                position: absolute !important;
                content: "" !important;
                background-attachment: scroll !important;
                background-size: cover !important;
                transform: none !important;
                width: 40px !important;
                height: 40px !important;
                border: 0 none;
                left: auto !important;
                top: -20px !important;
                right: -20px !important;
            }
        }
    }
    .rts-image-check-box input[type=radio] {
        display: none;
    }
    .rts-image-check-box input[type=radio] ~ label::before {
        opacity: 0;
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        left: 0;
        top: 0;
        border: 0 none;
        border-radius: 0;
    }
}


.preview-course-button-area{
    display: flex;
    align-items: center;
    margin-top: 25px;
    gap: 25px;
    @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
    }
    button{
        max-width: 100%;
        display: flex;
        align-items: center;
        grid-auto-flow: 10px;
        justify-content: center;
        i{
            margin-left: 10px;
            margin-right: 10px;
        }
        &.btn-primary{
            flex-basis: 40%;
        }
    }
}



.thumbnail-jointeam-one{
    position: relative;
    z-index: 1;
    .shape-area-one{
        img{
            position: absolute;
            z-index: -1;
            &.one{
                top: 12%;
                left: 7%;
            }
            &.two{
                top: 20%;
                right: 150px;
            }
            &.three{
                top: 30%;
                right: 190px;
            }
            &.four{
                top: 10%;
                left: 10%;
            }
        }
    }
}



.join-our-team-area.v-1{
    position: relative;
    z-index: 1;
    .shape-area{
        img{
            position: absolute;
            right: -2%;
            top: 20%;
            z-index: -1;
        }
    }
    .title-area-left-style{
        .title{
            font-weight: 600;
            br{
                @media #{$smlg-device} {
                    display: none;
                }
                @media #{$sm-layout} {
                    display: none;
                }
            }
        }
        p{
            max-width: 80%;
        }
    }
}


.rts-404-area-start{
    position: relative;
    height: 100vh;
    width: 100%;
    .inner-content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50% , -50%);
        text-align: center;
        @media #{$large-mobile} {
            width: 100%;
        }
        h1.title{
            font-size: 180px;
            margin-bottom: 50px;
            @media #{$large-mobile} {
                font-size: 80px;
            }
        }
        p.disc{
            max-width: 80%;
            margin: auto;
            margin-bottom: 40px;
            @media #{$large-mobile} {
                max-width: 100%;
            }
        }
        .rts-btn{
            margin: auto;
        }
    }
}