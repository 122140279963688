
// dashboard area start

.dashboard-banner-area-start{
    height: 300px;
    background-image: url(../images/dashboard/01.jpg);
    border-radius: 6px;
    position: relative;
    @media #{$large-mobile} {
        height: 500px;
    }
    &.student-dashboard{
        background-image: url(../images/dashboard/05.jpg);
        .rating-area-banner-dashboard{
            right: 28%;
            @media #{$large-mobile} {
                right: auto;
            }
        }
    }
    .rating-area-banner-dashboard{
        position: absolute;
        max-width: 235px;
        right: 60px;
        bottom: 35px;

        @media #{$md-layout} {
            bottom: 19px;
            left: 297px;
            right: auto;
        }
        @media #{$sm-layout} {
            left: 297px;
            right: auto;
        }
        @media #{$large-mobile} {
            left: 30px;
            right: auto;
            bottom: 20px;
        }
        @media #{$small-mobile} {
            left: 20px;
            right: auto;
        }
        .stars{
            display: flex;
            align-items: center;
            gap: 5px;
            margin-bottom: 15px;
            @media #{$large-mobile} {
                display: none;
            }
            span{
                color: #fff;
                margin-right: 10px;
            }
            i{
                color: #FFA41C;
            }
        }
        p{
            color: #fff;
            margin-bottom: 15px;
            @media #{$large-mobile} {
                display: none;
            }
        }
        .create-btn{
            border-radius: 4px;
            border: 1px solid var(--ffffff, #FFF);
            color: #fff;
            padding: 12px 20px;
            transition: .3s;
            display: block;
            &:hover{
                background: var(--color-primary);
                border: 1px solid transparent;
            }
        }
    }
    .author-profile-image-and-name{
        position: absolute;
        bottom: -60px;
        left: 60px;
        display: flex;
        align-items: center;
        @media #{$md-layout} {
            bottom: 22%;
            left: 30px;
        }
        @media #{$sm-layout} {
            bottom: 22%;
            left: 30px;
        }
        @media #{$large-mobile} {
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
        }
        @media #{$small-mobile} {
            left: 18px;
        }
        .profile-pic{
            padding: 10px;
            border-radius: 50%;
            background: #fff;
            position: relative;
            overflow: hidden;
            @media #{$small-mobile} {
                border-radius: 50%;
            }
            img{
                transition: .3s;
            }
        }
        .name-desig{
            margin-left: 50px;
            margin-top: -50px;
            @media #{$sm-layout} {
                    margin-top: -111px;
            }
            @media #{$large-mobile} {
                margin-top: 0;
                margin-left: 0;
            }
            .title{
                color:  #FFF;
                font-size: 44px;
                font-style: normal;
                font-weight: 600;
                line-height: 54px;
                margin-bottom: 5px;
            }
            .course-vedio{
                display: flex;
                align-items: center;
                gap: 30px;
                @media #{$small-mobile} {
                    gap: 5px;
                    flex-direction: column;
                    align-items: flex-start;
                }
                i{
                    color: #fff;
                }
                span{
                    color: #fff;
                    font-weight: 300;
                }
            }
        }
    }
}




.left-sindebar-dashboard{
    border-radius: 6px;
    border: 1px solid#DDD8F9;
    background: #FFF;
    padding: 40px !important;
    @media screen and (max-width:1200px) {
        padding: 30px !important;
    }
    @media #{$large-mobile} {
        padding: 20px !important;
    }
    .dashboard-left-single-wrapper{
        .title{
            color:  #110C2D;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        .single-item{
            padding: 18px 0;
            display: flex;
            align-items: start;
            gap: 15px;
            border-bottom: 1px solid #DDD8F9;
            &:first-child{
                padding-top: 0;
            }
			
            i{
                transition: 0.3s;
                flex-basis: 8%;
                margin-top: 4px;
            }
            p{
                color: #737477;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                margin-bottom: 0;
                transition: .3s;
            }
            &.active{
                i{
                    color: var(--color-primary);
                }
                p{
                    color: var(--color-primary);
                }
            }
            &:hover{
                i{
                    color: var(--color-primary);
                }
                p{
                    color: var(--color-primary);
                }
            }
        }
        &.bbnone{
            .single-item{
                &:last-child{
                    border-bottom: none;
                }
            }
        }
    }
}


.right-sidebar-dashboard{
    border-radius: 6px;
    border: 1px solid  #DDD8F9;
    padding: 30px;
    @media #{$large-mobile} {
        padding: 0px;
        border: none;
    }
}
.single-dashboard-card{
    border-radius: 6px;
    border: 1px solid #DDD8F9;
    background:  #FFF;
    text-align: center;
    padding: 47px;
    @media screen and (max-width:1200px) {
        padding: 30px 20px;
    }
    .icon{
        background: #F9F8FF;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        justify-content: center;
        margin: auto;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DDD8F9;
        i{
            font-size: 26px;
            color: var(--color-primary);
        }
    }
    .title{
        color:  #553CDF;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 54px;
        margin-bottom: 0px;
    }
    p{
        margin-bottom: 0;
        color:  #737477;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }
}

.single-progress-course{
    padding: 30px;
    border-radius: 6px;
    border: 1px solid  #DDD8F9;
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
    }
    @media #{$large-mobile} {
        padding: 20px;
    }
    &:last-child{
        margin-bottom: 0;
    }
    &:hover{
        .thumbnail{
            img{
                transform: scale(1.1);
            }
        }
    }
    .thumbnail{
        display: block;
        overflow: hidden;
        min-width: max-content;
        border-radius: 6px;
        @media #{$sm-layout} {
            min-width: 100%;
        }
        img{
            transition: .3s;
            min-width: max-content;
            border-radius: 6px;
            @media #{$sm-layout} {
                min-width: 100%;
            }
        }
    }
    .information-progress-course{
        width: 100%;
        .rating-area{
            display: flex;
            align-items: center;
            gap: 3px;
            i{
                color: #FFA41C;
            }
        }
        a{
            .title{
                color:  #110C2D;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                margin-bottom: 5px;
                margin-top: 15px;
                transition: .3s;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        .comp{
            margin-bottom: 10px;
            display: block;
        }
        .progress-wrapper-lesson-compleate{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 30px;
            .progress{
                flex-basis: 90%;
                height: 4px;
                .progress-bar{
                    background-color: #553CDF;
                }
            }
            .end{
                min-width: max-content;
            }
        }
    }
}

.title-between-dashboard{
    display: flex;
    align-items: center;
    justify-content: space-between;
    a{
        color: #110C2D;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
    }
}

.my-course-enroll-wrapper-board{
    border: 1px solid #DDD8F9;
    border-radius: 6px;
    padding: 0;
    .single-course-inroll-board{
        &.head{
            p{
                color:  #110C2D;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
            }
        }
        &:last-child{
            border-bottom: none;
        }
        padding: 22px 24px;
        border-bottom: 1px solid #DDD8F9;
        display: flex;
        align-items: center;
        .rating{
            i{
                color: #FFA41C;
                @media #{$large-mobile} {
                    font-size: 12px;
                }
            }
        }
        &>div{
            flex-basis: 33%;
        }
    }
}

.right-sidebar-my-profile-dash{
    border-radius: 6px;
    border: 1px solid #DDD8F9;
    padding: 30px;
    @media #{$large-mobile} {
        padding: 15px;
    }
}

.my-single-portfolio-dashed{
    display: flex;
    align-items: flex-start;
    padding-bottom: 30px;
    @media #{$large-mobile} {
        flex-direction: column;
    }
    &:last-child{
        padding-bottom: 0;
    }
    .name{
        flex-basis: 40%;
        color:  #110C2D;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 27px;
    }
    .value{
        flex-basis: 60%;
    }
}



.exrolled-course-wrapper-dashed{
    border-radius: 6px;
    border: 1px solid #DDD8F9;
    padding: 30px;
    @media #{$small-mobile} {
        padding: 0;
        border: none;
    }
    .nav-tabs{
        margin-bottom: 0;
        border-bottom: 1px solid #DDD8F9;
        li{
            margin: 0;
            padding: 0;
            margin: 0 15px;
            &:first-child{
                margin-left: 0;
            }
            button{
                padding: 10px 0;
                border: none;
                margin: 0 0;
                color:  #110C2D;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                &.active{
                    border-bottom: 3px solid #553CDF;
                    color:  #553CDF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 28px;
                }
            }
        }
    }
}



.single-course-style-three{
    .progress-wrapper-lesson-compleate{
        margin-top: 10px;
        .compleate{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
        }
        .progress{
            height: 4px;
            .progress-bar{
                background-color: var(--color-primary);
            }
        }
    }
    &.enroll-course{
        .rts-btn{
            margin-top: 20px;
            max-width: 100%;
            padding: 12px 25px;
        }
    }
}


.footer-dashboard{
    background: #110C2D;
    .footer-dashboard-inner{
        display: flex;
        align-items: center;
        padding: 20px 0;
        justify-content: space-between;
        @media #{$sm-layout} {
            flex-direction: column;
            align-items: center;
            gap: 15px;
            text-align: center;
        }
        p{
            margin-bottom: 0;
            a{
                color: #fff;
                transition: .3s;
                &:hover{
                    color: var(--color-primary);
                }
            }
        }
        .social-area-dashboard-footer{
            ul{
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                gap: 24px;
                list-style: none;
                li{
                    margin: 0;
                    padding: 0;
                    a{
                        i{
                            color: #fff;
                            transition: .3s;
                        }
                        &:hover{
                            i{
                                color: var(--color-primary);
                            }
                        }
                    }
                }
            }
        }
    }
}


.rts-reviewd-area-dashed{
    border-radius: 6px;
    border: 1px solid #DDD8F9;
    padding: 30px;
}


.rts-reviewd-area-dashed{
    .table-reviews{
        width: 100%;
        border: 1px solid #DDD8F9;
        border-radius: 6px !important;
        thead{
            tr{
                th{
                    padding: 12px 20px;
                    background: #F9F8FF;
                }
            }
        }
        tbody{
            tr{
                border-top: 1px solid #DDD8F9;
                td{
                    padding: 20px;
                    .author-area{
                        display: flex;
                        align-items: center;
                        gap: 14px;
                        p{
                            color:  #110C2D;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                        }
                        .profile-picture{
                            min-width: max-content;
                            img{

                            }
                        }
                    }
                    .stars{
                        margin-top: 5px;
                        i{
                            color: #FFA41C;
                        }
                        span{
                            margin-left: 5px;
                            color: #110C2D;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                        }
                    }
                }
                &.odd{
                    td{
                        background: #F9F8FF;
                    }
                }
            }
        }
        &.quiz{
            thead{
                tr{
                    th{
                        text-transform: capitalize !important;
                        color:  #110C2D;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        padding: 20px;
                        .information-quiz{
                            p.quiz{
                                margin-top: 4px;
                                margin-bottom: 5px;
                                color: #110C2D;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                            }
                            p .quiz{
                                margin-bottom: 10px;
                                color: #110C2D;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                            }
                        }
                        .rts-btn{
                            text-decoration: none;
                            padding: 5px 12px;
                            border-radius: 2px;
                        }
                        .pass{
                            display: block;
                            padding: 2px 12px;
                            background: #E4FFEA;
                            border-radius: 2px;
                            color: #3FA15A;
                            border: 1px solid #3FA15A;
                        }
                        .fail{
                            display: block;
                            border-radius: 2px;
                            padding: 2px 12px;
                            background: #FFEBEB;
                            border: 1px solid #FFBEBE;
                            color: rgba(223, 60, 60, 1);
                        }
                        .hold-area{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .hold{
                                border-radius: 3px;
                                border: 1px solid #ED9770;
                                background: #FCF0D9;
                                padding: 2px 13px;
                                color: #ED9770;
                            }
                            .processing{
                                color: #7093ED;
                                text-align: center;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 24px;
                                border-radius: 3px;
                                border: 1px solid #7093ED;
                                background: #F1F4FF;
                                padding: 2px 13px;
                            }
                            .success{
                                color: #159648;
                                text-align: center;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 24px;
                                border-radius: 3px;
                                border: 1px solid #159648;
                                background: #DCFCD9;
                                padding: 2px 13px;
                            }
                            .hold-i{
                                width: 36px;
                                height: 36px;
                                background: #F3F1FF;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                justify-content: center;
                                transition: .3s;
                                i{
                                    color: var(--color-primary);
                                    transition: .3s;
                                }
                                &:hover{
                                    background: var(--color-primary);
                                    i{
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }        
                }
            }
        }
    }
}

.pagination-full-width{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px;
    border-radius: 3px;
    border: 1px solid  #DDD8F9;
    @media #{$large-mobile} {
        justify-content: center;
    }
    .pagination{
        ul{
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            list-style: none;
            gap: 20px;
            li{
                margin: 0;
                a{
                    color: #737477;
                    &.next,
                    &.prev{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30px;
                        height: 30px;
                        border-radius: 2px;
                        background: #F9F8FF;
                        transition: .3s;
                        i{
                            color: var(--color-primary);
                            transition: .3s;
                        }
                        &:hover{
                            background: var(--color-primary);
                            color: #fff;
                            i{
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    span{
        @media #{$large-mobile} {
            display: none;
        }
    }
}

#ui-datepicker-div{
    border: 1px solid #c5c5c5;
    position: relative;
    z-index: 10000;
    max-width: 100%;
    min-width: max-content;
}


.calender-and-tab-btn-between{
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .nav-tabs{
        padding: 0;
        margin: 0;
        border: none;
        gap: 10px;
        li{
            margin: 0;
            padding: 0;
            border: none;
            button{
                border-radius: 4px;
                border: 1px solid #553CDF;
                padding: 7px 15px;
                border-radius: 6px;
                color: #553CDF;
                &[aria-selected="true"]{
                    background: var(--color-primary);
                    color: #fff;
                }
                &:hover{
                    background: var(--color-primary);
                    color: #fff;
                }
            }
        }
    }
    .date-picker-area{
        max-width: 400px;
        position: relative;
        i{
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
            @media #{$large-mobile} {
                top: 25%; 
            }
            @media #{$small-mobile} {
                top: 16%; 
            }
        }
        input{
            border-radius: 4px;
            border: 1px solid  #DDD8F9;
            height: 42px;
        }
    }
}

tbody{
    &.questions-answer{
        tr{
            td{
                .students-questions{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    .information-q{
                        p{
                            margin: 0;
                            color:  #110C2D;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 24px;
                        }
                    }
                }
                .questions{
                    padding-left: 20px;
                    position: relative;
                    &::after{
                        left: 0;
                        top: 8px;
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: var(--color-primary);
                        content: '';
                    }
                }
                .status-btn-wrapper{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .button-area{
                        display: flex;
                        align-items: center;
                        gap: 25px;
                        i{
                            color: var(--color-primary);
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.short-by--category-sash{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 30px;
    .nice-select-wrap{
        border-radius: 6px;
        border: 1px solid  #DDD8F9;
        max-width: 320px;
        .drop{        
            padding: 27px 20px;
            font-size: 18px;
            line-height:0;
            &::after{
                top: 44%;
                right: 26px;
            }
        }
        ul{
            width: 100%;
            li{
                line-height: 0;
                a{
                    padding: 18px 18px;
                    line-height: 1;
                }
            }
        }
    }
}


.announcements-wrapper-dashed{
    border-radius: 6px;
    border: 1px solid #DDD8F9;
    padding: 30px;
    @media #{$small-mobile} {
        padding: 0;
        border: none;
    }
}

.calender-area-wrapper{
    border-radius: 6px;
    border: 1px solid #DDD8F9;
    padding: 30px;
    @media #{$small-mobile} {
        padding: 0;
        border: none;
    }
    .search-area-calender-inner{
        position: relative;
        input{
            border-radius: 6px;
            border: 1px solid  #DDD8F9;
            height: 46px;
            padding-left: 50px;
            &:focus{
                border: 1px solid var(--color-primary);
            }
        }
        i{
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
            font-weight: 400;
        }
    }
}

.assignment-list-wrapper-calender{
    margin-top: 30px;
    padding: 24px;
    border-radius: 6px;
    border: 1px solid #DDD8F9;
    @media #{$small-mobile} {
        padding: 15px;
    }
}

.single-assignments-wrapper{
    .top-wrapper{
        margin-bottom: 35px;
    }
    .assignment-list{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 14px;
        border-radius: 5px;
        border: 1px solid #DDD8F9;
        @media #{$large-mobile} {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;        
        }
        .left{
            display: flex;
            align-items: center;
            gap: 12px;
            @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
            }
            p{
                margin: 0;
            }
            span{
                margin-left: 20px;
                @media #{$large-mobile} {
                    margin-left: 0;
                }
            }
            i{
                color: var(--color-primary);
            }
        }
    }
}




.announcements-wrapper-dashed{
    .top-announcement-wrapper{
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;
        border: 1px solid  #DDD8F9;
        @media #{$large-mobile} {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }
        .left-wrapper{
            display: flex;
            align-items: center;
            gap: 18px;
            @media #{$small-mobile} {
                flex-direction: column;
                align-items: flex-start;
            }
            .information{
                p{
                    color:  #110C2D;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    margin-bottom: 0;
                    margin-top: 7px;
                }
            }
        }
        .right{
            button{
                padding: 12px 25px;
                &:focus{
                    box-shadow: none;
                    border: 1px solid var(--color-primary);
                }
                &:hover{
                    border: 1px solid var(--color-primary);
                }
            }
        }
    }
    .course-short-by-date-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        margin-top: 30px;
        @media #{$sm-layout} {
            flex-direction: column;
            align-items: flex-start;
        }
        .single-course-filter{
            flex-basis: 40%;
            @media #{$sm-layout} {
                width: 100%;
            }
            &>span{
                margin-bottom: 20px;
                display: block;
            }
            &.short-by{
                flex-basis: 30%;
            }
            .date-picker-area{
                position: relative;
                input{
                    border-radius: 4px;
                    border: 1px solid #DDD8F9;
                    height: 56px;
                }
                i{
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    pointer-events: none;
                }
            }
        }
        .nice-select-wrap{
            border-radius: 6px;
            border: 1px solid  #DDD8F9;
            .drop{        
                padding: 27px 20px;
                font-size: 18px;
                line-height:0;
                &::after{
                    top: 44%;
                    right: 26px;
                }
            }
            ul{
                width: 100%;
                li{
                    line-height: 0;
                    a{
                        padding: 18px 18px;
                        line-height: 1;
                    }
                }
            }
        }
    }
    .rts-reviewd-area-dashed{
        border-radius: 0;
        border: none;
        padding: 0;
        margin-top: 30px;
    }
    .announcement{
        .announcement-1{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left{
                p{
                    margin-bottom: 0;
                    color:  #110C2D;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
            .right{
                display: flex;
                align-items: center;
                gap: 30px;
                i{
                    cursor: pointer;
                }
            }
        }
    }
}


body{
    padding-right: 0  !important;
}
.announcement{
    &.modal{
        top: 20% !important;
        .modal-header{
            background: #FFF;
            padding: 15px 25px;
        }
        .modal-body{
            padding: 25px;
            background: #F9F8FF;
            .nice-select-wrap{
                height: 50px;
                border-radius: 4px;
                border: 1px solid  #DDD8F9;
                background:  #FFF;
                padding: 8px;
                .drop{
                    font-size: 15px;
                }
                #price{
                    width: 50% !important;
                    left: 0;
                    border-radius: 0 !important;
                    top: 100% !important;
                    border: 1px solid #DDD8F9 !important;
                    @media #{$large-mobile} {
                        width: 100% !important;
                    }
                }
            }
            .single-input{
                margin-bottom: 15px;
                label{
                    margin-bottom: 8px;
                    display: block;
                }
                input{
                    height: 50px;
                    border-radius: 4px;
                    border: 1px solid  #DDD8F9;
                    background:  #FFF;
                    padding: 8px 20px;
                    &:focus{
                        border: 1px solid var(--color-primary);
                    }
                }
                textarea{
                    border-radius: 4px;
                    border: 1px solid  #DDD8F9;
                    background:  #FFF;
                    height: 140px;
                    padding: 8px 20px;
                    &:focus{
                        border: 1px solid var(--color-primary);
                    }
                }
            }
        }
        .modal-footer{
            button{
                max-width: 100%;
                padding: 12px;
            }
        }
        .modal-dialog{
            max-width: 828px;
        }
    }
}

.withdrow-inner-wrapper{
    margin-top: 23px;
    text-align: center;
    .top{
        display: flex;
        align-items: center;
        gap: 12px;
        p{
            color:  #737477;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            a{
                color:  #553CDF;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }
    }
    .mid{
        margin: 70px;
    }
    p{
        color:  #110c2d;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; 
    }
}

.betweena-area-assignment{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.certificates-wrapper-dashed{
    border-radius: 6px;
    border: 1px solid #DDD8F9;
    padding: 30px;
    @media #{$large-mobile} {
        padding: 0;
        border: none;
    }
    h5.title{
        color:  #737477;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 34px;
    }
}

.create-certificates-top-main-wrapper{
    padding: 40px;
    border-radius: 6px;
    border: 1px solid #DDD8F9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media #{$large-mobile} {
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;    
    }
    &>.left{
        flex-basis: 55%;
        .title{
            color:  #110C2D;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 34px;
            margin-bottom: 10px;
        }
        p.disc{
            color:  #737477;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 20px;
        }
        a.rts-btn{
            padding: 13px 15px;
            i{
                margin-right: 5px;
            }
        }
    }
}


.all-certificates-main-wrapper-dashed{
    border: 1px solid #DDD8F9;
    .single-certificates{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 26px;
        border-bottom: 1px solid #DDD8F9;
        @media #{$sm-layout} {
            flex-direction: column;
            align-items: flex-start;
        }
        &:last-child{
            border: none;
        }
        .left{
            display: flex;
            align-items: center;
            gap: 15px;
            @media #{$small-mobile} {
                flex-direction: column;
                align-items: flex-start;
            }
            .title{
                margin-bottom: 0;
                color:  #110C2D;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 34px;
            }
        }
        .right{
            display: flex;
            align-items: center;
            gap: 26px;
            padding: 15px 0;
            .draft-btn{
                border-radius: 30px;
                border: 1px solid  #DDD8F9;
                background: #F9F8FF;
                padding: 6px 14px;
                width: 164px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                span{
                    margin-left: -30px;
                }
                &.published-btn{
                    border: 1px solid #09A24F;
                    color: #09A24F;
                    background: #E5FFEF;
                    span{
                        margin-left: -10px;
                    }
                }
            }
        }
    }
}


.settings-wrapper-dashed{
    border-radius: 6px;
    border: 1px solid #DDD8F9;
    padding: 30px;
}


.settings-wrapper-dashed{
    .tab-buttons{
        border: none;
        box-shadow: none;
        display: flex;
        gap: 50px;
        border-bottom: 1px solid #D9D9D9;
        li{
            margin: 0;
            button{
                color:  #737477;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
                padding: 15px 0;
                border-radius: 0;
                background: transparent;
                &[aria-selected="true"]{
                    background: transparent;
                    color: var(--color-primary);
                    border-bottom: 2px solid 
                    var(--color-primary);
                }
            }
        }
    }
}


.settings-wrapper-dashed{
    .social-profile-link-wrapper{
        margin-top: 30px;
        .single-profile-wrapper{
            display: flex;
            align-items: center;
            max-width: 90%;
            margin-bottom: 25px;
            @media #{$large-mobile} {
                max-width: 100%;
            }
            @media #{$small-mobile} {
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;
            }
            .left{
                flex-basis: 40%;
                .icon{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                    span{
                        transition: .3s;
                    }
                    &:hover{
                        span{
                            color: var(--color-primary);
                        }
                    }
                }
            }
            .right{
                flex-basis: 60%;
                input{
                    border-radius: 4px;
                    border: 1px solid  #DDD8F9;
                    height: 56px;
                    width: 100%;
                    &:focus{
                        border: 1px solid var(--color-primary);
                    }
                }
            }
        }
        .rts-btn{
            padding: 12px 25px;
        }
    }
}

.setting-change-password-area{
    .form-password-area{
        margin-top: 25px;
        .single-input{
            max-width: 80%;
            margin-bottom: 25px;
            @media #{$large-mobile} {
                max-width: 100%;
            }
            label{
                margin-bottom: 10px;
            }
            input{
                height: 56px;
                border: 1px solid #DDD8F9;
                border-radius: 4px;
            }
        }
        .rts-btn{
            padding: 12px 25px;
        } 
    }
}