
.calender-dash-wrapper{
    html {
        font-family: sans-serif;
        font-size: 15px;
        line-height: 1.4;
        color: #444;
    }
    
    body {
        margin: 0;
        background: #504f4f;
        font-size: 1em;
    }
    
    .wrapper {
        margin: 15px auto;
        max-width: 100%;
    }
    
    .container-calendar {
        background: #ffffff;
        padding: 0;
        max-width: 100%;
        margin: 0 auto;
        overflow: auto;
    }
    
    .button-container-calendar button {
        cursor: pointer;
        display: inline-block;
        zoom: 1;
        background: #00a2b7;
        color: #fff;
        border: 1px solid #0aa2b5;
        border-radius: 4px;
        padding: 5px 10px;
    }
    
    .table-calendar {
        border-collapse: collapse;
        width: 100%;
    }
    
    .table-calendar td, .table-calendar th {
        padding: 5px;
        border: 1px solid #ddd8f9;
        text-align: center;
        vertical-align: top;
    }
    
    .date-picker.selected {
        font-weight: bold;
        background: var(--color-primary);
        color: #fff;
    }
    
    .date-picker.selected span {
        border-bottom: 2px solid currentColor;
    }
    
    /* sunday */
    .date-picker:nth-child(1) {
      color: var(--color-primary);
    }
    
    /* friday */
    .date-picker:nth-child(6) {
      color: var(--color-primary);
    }
    
    #monthAndYear {
        text-align: center;
        margin-top: 0;
    }
    
    .button-container-calendar {
        position: relative;
        margin-bottom: 1em;
        overflow: hidden;
        clear: both;
    }
    
    #previous {
        float: left;
    }
    
    #next {
        float: right;
    }
    
    .footer-container-calendar {
        padding: 10px 0;
        display: flex;
        align-items: center;
        gap: 20px;
    }
    
    .footer-container-calendar select {
        cursor: pointer;
        display: inline-block;
        zoom: 1;
        background: #ffffff;
        color: #585858;
        border: 1px solid #bfc5c5;
        border-radius: 3px;
        padding: 5px 1em;
        max-width: 120px;
    }
}