.nice-select-wrap {
    margin: 1rem auto 0;
    width: 100%;
    border-radius: 0;
    overflow: visible;
    margin-right: auto;
    position: relative;
    right: auto;
    left: 0;
    margin-left: 0;
    height: 56px;
    top: 0;
    margin-top: 0;
    border-radius: 6px;
    &.down {
      background-color: white;
      
      .drop::after {
        content: '\f077';
      }
    }
    .drop, ul a {
      width:100%;
      display: block;
      padding: 2px 11px;
      text-decoration: none;
      color: #666;
      font-size: .88rem;
      line-height: 1.88;
      height: 2.5rem;
    }
    
    .drop {
      position: relative;
      &::after {
        content: '\f078';
        position: absolute;
        text-align: center;
        width: 1rem;
        height: 1rem;
        top: 50%;
        right: 1rem;
        margin-top: -.5rem;
        color: #666;
        line-height: .94;
        transition: all 300ms ease;
        font-family: var(--font-3);
      }
    }
    
    ul {
        width: 100%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: visible;
        position: absolute;
        z-index: 100000;
        background: #fff;
        border-radius: 10px;
        width: 170px;
      a {
        position: relative;
        font-size: 16px;
        &:hover {
          color: var(--color-primary);
        }
        
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          background: linear-gradient(
            to right,
            rgba(245,245,245,0) 0%,
            rgba(245,245,245,1) 10%,
            rgba(245,245,245,1) 90%,
            rgba(245,245,245,0) 100%
          );
        }
      }
    }
}