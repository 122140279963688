
.contact-area-right-5{
    .title{
        color: #111;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 50px;
        @media #{$large-mobile} {
            font-size: 26px;
            line-height: 37px;        
        }
    }
    label{
        color: #141414;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 15px;
    }
    .single-input{
        margin-bottom: 25px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .contact-form-1{
        input{
            border: 1px solid  #DDD8F9;
            height: 50px;
            padding: 10px;
            color: #111;
            &:focus{
                border-color: var(--color-primary);
            }
        }
        textarea{
            padding: 10px;
            height: 100px;
            border: 1px solid  #DDD8F9;
            color: #111;
            &:focus{
                border-color: var(--color-primary);
            }
        }
    }
}
.contact-area-right-7{
    .title{
        color: #111;
        font-family: 'Teko', sans-serif;
        font-size: 64px;
        font-weight: 600;
        line-height: 64px;
        text-transform: uppercase; 
        @media(max-width:1440px){
            font-size: 56px;
            line-height: 56px;
        }
        @media(max-width:1200px) and (min-width:991px){
            font-size: 46px;
            line-height: 46px;
        }
        @media(max-width:576px){
            font-size: 46px;
            line-height: 46px;
        }
        @media(max-width:450px){
            font-size: 32px;
            line-height: 32px;
        }
    }
    label{
        color: #141414;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 15px;
    }
    .single-input{
        margin-bottom: 25px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .contact-form-1{
        input{
            border: 1px solid #1B1925;
            height: 50px;
            padding: 10px;
            color: #111;
            &:focus{
                border-color: var(--color-primary-gym);
            }
        }
        textarea{
            padding: 10px;
            height: 100px;
            border: 1px solid #1B1925;
            color: #111;
            &:focus{
                border-color: var(--color-primary-gym);
            }
        }
    }
}

.contact-left-area-start{
    .title{
        color:  #110C2D;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 50px; 
        @media #{$large-mobile} {
            font-size: 26px;
            line-height: 1.3;
        }
        span{
            color:  #110C2D;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            @media #{$large-mobile} {
            font-size: 26px;
            line-height: 1.3;
            }
        }
    }
}

.contact-page-form{
    .single-input{
        margin-bottom: 20px;
    }
    label{
        color:  #110C2D;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 15px;
    }
    input{
        height: 50px;
        border-radius: 4px;
        border: 1px solid  #DDD8F9; 
    }
    textarea{
        border-radius: 4px;
        border: 1px solid  #DDD8F9;
        height: 100px;
        &:focus{
            border: 1px solid var(--color-primary);
        }
    }
}

.contact-map-area-start{
    display: flex;
    align-items: center;
    gap: 30px;
    @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
    }
    &>div{
        flex-basis: 25%;
    }
    .single-maptop-info{
        .icon{
            margin-bottom: 15px;
        }
    }
}

.login-page{
    height: 100vh !important;
    overflow: visible;
}

.login-page-form-area{
    position: relative;
    max-width: 583px;
    width: 583px;
    border-radius: 6px;
    border: 1px solid #DDD8F9;
    padding: 60px;
    background:  #FFF;
    @media screen and (max-width:757px) {
        width: 100%;
        padding: 30px;
    }
    @media screen and (max-width:991px) {
        margin: 0 auto;
    }
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    .title{
        text-align: center;
        color: #000;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        margin-bottom: 40px;
    }
    .single-input-wrapper{
        margin-bottom: 20px;
    }
    label{
        color:  #110C2D;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 15px;
    }
    input{
        border-radius: 4px;
        border: 1px solid  #DDD8F9;
        background:  #FFF;
        height: 56px;
    }
    button{
        max-width: 100%;
    }
    .google-apple-wrapper{
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        margin-top: 30px;
        .google{
            padding: 10px 20px;
            border: 1px solid #E2E2FF;
            border-radius: 4px;
            cursor: pointer;
            img{
                height: auto;
                max-width: max-content;
            }
        }
    }
    p{
        margin: auto;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 0;
        a{
            color: var(--color-primary);
        }
    }
    .half-input-wrapper{
        display: flex;
        align-items: center;
        gap: 10px;
        @media screen and (max-width:576px) {
            flex-wrap: wrap;
        }
        &>div{
            flex-basis: 50%;
            @media screen and (max-width:576px) {
                flex-basis: 100%;
            }
        }
    }
}
.login-registration-wrapper {
	position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    &::after{
        position: absolute;
        left: 0;
        top: -15%;
        width: 48%;
        height: 115%;
        content: '';
        z-index: -1;
        background: var(--color-white);
        @media screen and(max-width:1200px) {
            width: 35%;
        }
        @media screen and(max-width:991px) {
            width: 100%;
        }
    }
    &::before{
        position: absolute;
        right: 0;
        top: -15%;
        width: 52%;
        height: 115%;
        content: '';
        z-index: -1;
        background: var(--color-primary);
        @media screen and(max-width:1200px) {
            width: 65%;
        }
        @media screen and(max-width:991px) {
            width: 0%;
        }
    }
}
.contact-thumbnail-login-p{
    @media screen and (max-width: 991px) {
        display: none;
    }
    text-align: center;
    img{
        @media screen and(max-width:1200px) {
            width: 400px;
        }
    }
}

.rts-contact-area.v-7{
    .contact-thumbnail-img{
        @media(max-width:991px){
            margin-bottom: 50px;
            img{
                width: 100%;
            }
        }
    }
}