

// why choose us

.bg-choose-us-one{
    background-image: url(../images/why-choose/01.jpg);
}
.btn-primary.rts-btn.hov--white{
    &:hover{
        background: #fff !important;
        border: 1px solid #fff !important;
    }
}
.why-choose-us-area-image{
    display: flex;
    align-items: flex-end;
    position: relative;
    img{
        border-radius: 6px;
        min-width: max-content;
        @media #{$laptop-device} {
            min-width: auto;
        }
        @media #{$smlg-device} {
            min-width: auto;
        }
        @media #{$large-mobile} {
            min-width: auto;
        }
        @media #{$small-mobile} {
            min-width: 100%;
        }
    }
    .border-img{
        position: relative;
        max-width: max-content;
        height: max-content;
        z-index: 1;
        .two{
            margin-bottom: -50px;
        }
        &::after{
            position: absolute;
            content: '';
            width: 90%;
            height: 125%;
            left: 70px;
            border: 1px solid #674EF2;
            top: -6%;
            border-radius: 6px;
            z-index: -1;
        }
    }
    .circle-animation{
        position: absolute;
        left: 23%;
        bottom: 34%;
        z-index: 10;
        border-radius: 50%;
        i{
            position: absolute;
            color: #fff;
            left: 42%;
            top: 36%;
            font-size: 33px;
        }
    }
    .uni-circle-text {
        display: block;
    }
    .uni-circle-text::before {
        content: "";
        display: block;
        width: 140px;
        height: 140px;
        border: 1px solid rgba(245, 245, 245, 0);
        position: absolute;
        transform: scale(0.5);
        left: 0;
        top: 0;
        transform: translate(0px, 0px);
        border-radius: 100%;
        background: #ffffff1f;
        backdrop-filter: blur(12px);
        z-index: -1;
    }
    .uni-circle-text-path {
        fill: #fff;
        height: auto;
        max-width: 140px;
        right: 10%;
        transform-origin: center;
        text-transform: uppercase;
    }
    .uni-animation-spin {
        display: inline-flex;
        animation: spin 10s linear 0s infinite;
        padding: 8px;
    }
    @keyframes spin {
        0% {
            transform: rotate(0)
        }
    
        50% {
            transform: rotate(180deg)
        }
    
        100% {
            transform: rotate(360deg)
        }
    }
    &.v-7{
        @media(max-width:576px){
            display: block;
        }
        .border-img{
            &::after{
                display: none;
            }
            @media(max-width:576px){
                max-width: 100%;
            }
            .two{
                @media(max-width:576px){
                    width: 100%;
                }
            }
        }
        .circle-animation{
            @media(min-width:991px){
                left: 34%;
            }
            @media(max-width:768px){
                left: 40%;
                bottom: 38%;            
            }
            @media(max-width:450px){
                left: 30%;            
            }
        }
        .uni-circle-text{
            &::before {
                content: "";
                display: block;
                width: 140px;
                height: 140px;
                position: absolute;
                transform: scale(0.5);
                left: 0;
                top: 0;
                transform: translate(0px, 0px);
                border-radius: 100%;
                z-index: -1;
                border: 1px solid var(--red, #B10D00);
                background: linear-gradient(180deg, #F32837 0%, rgba(243, 40, 55, 0.00) 100%);
                backdrop-filter: blur(10px); 
                @media(max-width:450px){
                    width: 120px;
                    height: 120px;
                }
            }
            svg{
                @media(max-width:450px){
                    max-width: 120px;
                }
            }

        }
    }
}

.why-choose-main-wrapper-1{
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 50px;
    @media #{$small-mobile} {
        gap: 10px;
    }
}

.single-choose-reason-1{
    padding: 11px;
    border-radius: 4px;
    border: 1px solid #674EF2;
    max-width: max-content;
    display: flex;
    align-items: center;
    gap: 23px;
    width: 165px;
    @media #{$small-mobile} {
        gap: 17px;
        width: 137px;
    }
    .icon{
        img{
            min-width: max-content;
        }
    }
    .title{
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 0;
        @media #{$small-mobile} {
            font-size: 14px;
        }
    }
}

.single-choose-reason-7{
    padding: 23px 18px;
    border-radius: 0;
    border: 1px solid #E7E7E7;
    max-width: 48%;
    display: flex;
    align-items: center;
    gap: 16px;
    @media(max-width:1300px) and (min-width:991px){
        max-width: 47%;
    }
    @media(max-width:576px){
        max-width: 100%;
        width: 100%;
    }
    .icon{
        width: 60px;
        img{
            width: 100%;
        }
    }
    .title{
        font-family: 'Teko', sans-serif;
        color: var(--color-heading-2);
        font-size: 32px;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 0;
        @media(max-width:1300px) and (min-width:991px){
            font-size: 20px;
        }
        @media(max-width:450px){
            font-size: 26px;
        }
    }
}

.btn-gym.border-btn{
    &:hover{
        border: 1px solid #E7E7E7;
    }
}

.why-choose-us{
    position: relative;
    z-index: 1;
    .shape-image{
        .shape{
            position: absolute;
            z-index: -1;
            @media screen and (max-width:575px) {
                display: none;
            }
            &.one{
                left: 6%;
                bottom: 30%;
            }
            &.two{
                left: 25%;
                top: 10%;
            }
            &.three{
                right: 15%;
                top: 20%;
                @media #{$laptop-device} {
                    top: 5%;
                }
                @media #{$smlg-device} {
                    top: 5%;
                }
            }
        }
    }
}

.title-area-left-style{
    &.style-7{
        .pre-title{
            span{
                font-family: 'Teko', sans-serif;
                font-size: 20px;
                color: var(--color-heading-2);
                margin-top: 7px;
            }
        }
        .title{
            color: var(--color-heading-2);
            font-family: 'Teko', sans-serif;
            font-size: 60px;
            line-height: 1;
            text-transform: uppercase;
            @media(max-width:1440px) and (min-width:1200px){
                font-size: 48px;
            }
            @media(max-width:1200px) and (min-width:991px){
                font-size: 38px;
            }
            @media(max-width:768px){
                font-size: 54px;
            }
            @media(max-width:576px){
                font-size: 42px;
            }
            @media(max-width:450px){
                font-size: 30px;
            }
        }
        .post-title{
            @media(max-width:1400px) and (min-width:991px){
                br{
                    display: none;
                }
            }
            @media(max-width:650px){
                br{
                    display: none;
                }
            }
        }
    }
}


.rts-service-main-wrapper-area{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    &>div{
        flex-basis: 23.2%;
        @media #{$md-layout} {
            flex-basis: 44%;
        }
        @media #{$sm-layout} {
            flex-basis: 44%;
        }
        @media #{$large-mobile} {
            flex-basis: 100%;
        }
    }
    .single-service-start{
        padding: 22px 5px 22px 22px;
        border-radius: 6px;
        border: 1px solid #DDD8F9;
        background: #F9F8FF;
        display: flex;
        align-items: flex-start;
        gap: 20px;
        transition: .3s;
        &:hover{
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
            border: 1px solid #7763E5;
        }
        .information{
            .title{
                margin-bottom: 2px;
                color: #110C2D;
                font-size: 21px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
            }
        }
    }
}