
// button style here


.rts-btn{
    display: block;
    max-width: max-content;
    padding: 14px 34px;
    border-radius: 4px;
    transition: .3s;
    font-weight: 500;
    color: var(--color-primary);
    @media(max-width:576px){
        padding: 10px 25px;
    }
    &.btn-primary{
        background: var(--color-primary) !important;
        color: #fff;
        border: 1px solid transparent;
        &:hover{
            border: 1px solid #BBB1F2;
            background: transparent !important;
            color: var(--color-primary) !important;
        }
    }
    &.with-arrow{
        display: flex;
        align-items: center;
        gap: 12px;
        min-width: max-content;
    }
    &.btn-border{
        border: 1px solid #BBB1F2;
        color: var(--color-primary);
        &:hover{
            background: var(--color-primary);
            color: #fff;
        }
    }
    &.btn-primary-white{
        background: #fff  !important;
        color: #110C2D;
        border: 1px solid transparent;
        &:hover{
            background: transparent !important;
            border-color: #fff;
            color: #fff;
        }
    }
    &.btn-gym{
        background: var(--color-primary-gym) !important;
        color: #fff;
        border: 1px solid transparent;
        border-radius: 0;
        padding: 14px 34px;
        &:hover{
            border: 1px solid var(--color-white);
            background: var(--color-white) !important;
            color: var(--color-primary-gym) !important;
        }
    }
    &.btn-kitchen{
        background: var(--color-primary-kitchen) !important;
        color: #fff;
        border: 1px solid transparent;
        border-radius: 4px;
        padding: 14px 34px;
        &:hover{
            border: 1px solid var(--color-primary-kitchen);
            background: none !important;
            color: var(--color-primary-kitchen) !important;
        }
    }
}

.btn-narrow-arrow{
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 600;
    i{
        color: var(--color-primary);
    }
}