// zoom meeting area start
.single-zoom-meeting-area{
    padding: 30px;
    border-radius: 6px;
    border: 1px solid #EFECFF;
    background:  #FFF;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
    @media #{$large-mobile} {
        padding: 15px;
    }
    .thumbnail{
        display: block;
        overflow: hidden;
        position: relative;
        border-radius: 6px;
        img{
            transition: .3s;
        }
    }
    .inner-content{
        padding-top: 24px;
        .head{
            display: flex;
            align-items: center;
            gap: 22px;
            @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
            }
            .single{
                display: flex;
                align-items: center;
                gap: 12px;
                i{
                    color: var(--color-primary);
                }
            }
        }
        a{
            display: block;
            margin-top: 10px;
            .title{
                color: #110C2D;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 34px;
                margin-bottom: 10px;
                transition: .3s;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        .bottom{
            display: flex;
            align-items: center;
            gap: 18px;
            @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
            }
            .left{
                display: flex;
                align-items: center;
                gap: 15px;
            }
            a{
                margin: 0;
                color:  #110C2D;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 34px;
            }
        }
    }
    &:hover{
        .thumbnail{
            img{
                transform: scale(1.15);
            }
        }
    }
}



.zoom-meeting-left-content-details{
    p.disc{
        margin-bottom: 30px;
    }
    .events-details-single-area{
        margin-bottom: 0px;
    }
    .key-takeways-wrapper{
        ul{
            padding: 0;
            list-style-type: circle;
            list-style: circle;
            list-style-position: inside;
            li{
                margin: 13px 0;
            }
        }
    }
}

.zoom-meeting-information-details{
    padding: 40px;
    border-radius: 6px;
    border: 1px solid  #DDD8F9;
    background:  #FFF;
    @media #{$large-mobile} {
        padding: 20px;
    }
    .single-information{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        border-bottom: 1px solid #DDD8F9;
    }
    p{
        color: #737477;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-top: 15px;
        span{
            color:  #110C2D;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
        }
    }
    button{
        min-width: 100%;
        &:last-child{
            margin-top: 20px;
        }
    }
}
